<template>
  <v-dialog
    :value="print_dialog"
    max-width="1200px"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-text style="height: 1200px" class="pt-3">
        <JSViewer
          language="ko"
          ref="reportViewer"
          :exportsSettings="exportsSettings"
        ></JSViewer>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div>
          <v-btn color="green" icon @click="emailDialogOpen()"
            ><v-icon>mdi-email</v-icon></v-btn
          >
        </div>
        <v-btn
          color="primary"
          @click="$store.commit('setIsPrintDialog', false)"
          text
        >
          닫 기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import "@grapecity/activereports-localization";
import { Viewer } from "@grapecity/activereports-vue";
import { Core } from "@grapecity/activereports";
// import "@grapecity/activereports/pdfexport";
import new_order from "!json-loader!@/assets/reports/new_order.rdlx-json";
import new_manufacture from "!json-loader!@/assets/reports/new_manufacture.rdlx-json";
import new_release from "!json-loader!@/assets/reports/new_release.rdlx-json";
import order from "!json-loader!@/assets/reports/order.rdlx-json";
import manufacture from "!json-loader!@/assets/reports/manufacture.rdlx-json";
import release from "!json-loader!@/assets/reports/release.rdlx-json";
// import manufacture from "!json-loader!@/assets/reports/Manufacture.rdlx-json";

import "@grapecity/activereports/styles/light-blue-ui.css";
import "@grapecity/activereports/styles/ar-js-viewer.css";
// import { FontStore } from "@grapecity/activereports/core";
import { downloadFile } from "@/api/file";
import { mapGetters } from "vuex";
export default {
  components: {
    JSViewer: Viewer,
  },
  computed: {
    print_dialog() {
      if (this.$store.state.isPrintDialog) this.onPreview();
      return this.$store.state.isPrintDialog;
    },
    ...mapGetters({
      attachmentList: "getAttachmentList",
    }),
  },
  data() {
    return {
      exportsSettings: {
        pdf: {
          author: "SoftMani",
          printing: "highResolution",
          copying: true,
          modifying: true,
          annotating: false,
          contentAccessibility: true,
          documentAssembly: false,
          pdfVersion: "1.4",
          autoPrint: false,
          filename: `Report`,
        },
      },
    };
  },
  methods: {
    async onPreview() {
      this.$nextTick(() => {
        const viewer = this.$refs.reportViewer.Viewer();
        const companySetting = this.$store.state.companySetting;
        const printFormMaps = {
          가공의뢰서:
            companySetting.print_type === 1 ? new_manufacture : manufacture,
          출고의뢰서: companySetting.print_type === 1 ? new_release : release,
          검사의뢰서: companySetting.print_type === 1 ? new_release : release,
          order: companySetting.print_type === 1 ? new_order : order,
        };
        const printForm = printFormMaps[this.$store.state.printType];

        printForm.DataSources[0].ConnectionProperties.ConnectString =
          "jsondata=" + JSON.stringify(this.$store.state.printForm);
        viewer.availableExports = ["pdf"];
        viewer.exportSetting = this.exportsSettings;
        viewer.toggleToolbar(true); //will show it

        Core.setLicenseKey(process.env.VUE_APP_REPORT_KEY);

        viewer.registerFont("fontsConfig.json");
        viewer.open(printForm);
      });
    },
    emailDialogOpen() {
      const item = this.$store.state.printForm.item.production_item;
      this.$store.commit("setEmailInput", {
        reciver: this.$store.state.printForm
          ? this.$store.state.printForm.reciver
          : "",
        title: `${
          this.$store.state.printType === "order"
            ? this.$store.state.printForm.buyer
            : typeof this.$store.state.printForm.place_manufacture === "object"
            ? this.$store.state.printForm.place_manufacture.name
            : this.$store.state.printForm.place_forward
        } / ${this.$store.state.printForm.order} / ${
          this.$store.state.printType
        }   `,
        content: `* 본 메일은 발신 전용으로 수신이 불가합니다.<p/>발신처 : ${
          this.$store.state.printForm.company
        } (TEL : ${
          this.$store.state.printForm.tel
            ? this.addAutoHyphen(this.$store.state.printForm.tel)
            : ""
        }}) <p/>발신자 : ${this.$store.state.auth_name} (${
          this.$store.state.auth_uuid
        })<p/>문서 유형 : ${this.$store.state.printType}<p/>오더 번호 : ${
          this.$store.state.printForm.order
        }<p/>ITEM NO : ${item} <p/>=======================================<p/>`,
        cabonCopy: "",
      });
      const viewer = this.$refs.reportViewer.Viewer();
      let options = {
        author: "SoftMani",
        printing: "highResolution",
        copying: true,
        modifying: true,
        annotating: false,
        contentAccessibility: true,
        documentAssembly: false,
        pdfVersion: "1.7",
        autoPrint: false,
        filename: `Report`,
      };
      let cancelCallback = function () {
        return false;
      };
      viewer
        .export("pdf", options, { cancel: cancelCallback })
        .then((result) => {
          const pdfName = `
          ${
            this.$store.state.printType === "order"
              ? this.$store.state.printForm.buyer
              : typeof this.$store.state.printForm.place_manufacture ===
                "object"
              ? this.$store.state.printForm.place_manufacture.name
              : this.$store.state.printForm.place_forward
          }_${this.$store.state.printForm.order}_${
            this.$store.state.printType
          }.pdf`;
          const pdf = this.blobToFile(result, pdfName);
          const formData = new FormData();

          formData.append("myFile", pdf);

          if (this.attachmentList.length) {
            this.attachmentList.forEach(async (x) => {
              const result = await downloadFile(
                this.$store.state.auth_company,
                x._id,
              );

              const blobFile = new Blob([result.data]);
              const fileData = this.attacheBlobToFile(blobFile, x.name);

              formData.append("myFile", fileData);
            });
          }
          this.$store.commit("setEmailFile", pdf);
          this.$store.commit("setEmailForm", formData);
        });

      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "email_dialog",
      });
    },
    blobToFile(theBlob, fileName) {
      return new File([theBlob.data], fileName, {
        lastModified: new Date().getTime(),
        type: theBlob.data.type,
      });
    },
    attacheBlobToFile(theBlob, fileName) {
      return new File([theBlob], fileName, {
        lastModified: new Date().getTime(),
      });
    },
    cleanupResources() {
      if (this.$refs.reportViewer) {
        const viewer = this.$refs.reportViewer.Viewer();
        viewer.dispose();
      }
    },
    closeDialog() {
      this.$store.commit("setIsPrintDialog", false);
      this.cleanupResources();
    },
  },
  mounted() {
    this.$store.dispatch("SET_API_LOADING", false);
  },
  beforeDestroy() {
    this.cleanupResources();
  },
};
</script>
